@import url('https://fonts.googleapis.com/css?family=Poppins');
/* @tailwind base; */
@tailwind components;
@tailwind utilities;

:root {
  --demo-margin-top: 30.67px !important;
  --demo-margin-bottom: 18.67px !important;
  --demo-margin-left: 49.13px !important;
  --demo-margin-right: 49.13px !important;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}