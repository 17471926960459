.paragraph-container {
  margin-top: 4rem;
  text-align: center;
}

.chapter {
  margin-bottom: 1rem;
}

.chapter-header {
  color: #1e1c80;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.para-header {
  color: gray;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: -15px;
  margin-left: -8px;
  position: absolute;
  bottom: 3px;
  width: 98%;
  display: flex;
  justify-content: space-between;
}

.paragraph {
  display: flex;
  padding: 1rem;
  margin: 0 auto 1rem;
  width: 80%;
  background-color: rgba(255, 255, 255, 0.5);
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  // border-left: 10px solid red;
}

.english-paragraph,
.dutch-paragraph-pair {
  flex: 1;
  padding: 0 1rem;
  margin-bottom: 7px;
}
.dutch-paragraph-pair {
  display: flex;
  flex-direction: row-reverse;
}

.paragraph-sentence {
  text-align: left;
  width: 100%;
}

.like-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 2.7rem;
  right: -3rem;
  transform: translateY(-50%);
}

.like-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-bottom: 10px;
}

.checkbox {
  display: flex;
  position: absolute;
  transform: translateY(-50%);
  top: 1rem;
  left: -3rem;
}

input[type="checkbox"] {
  display: grid;
  place-content: center;
  width: 20px;
  height: 20px;
  accent-color: #ffff;
}

input[type="checkbox"]::before {
  content: "";
  width: 16px;
  height: 16px;
  transform: scale(0);
  box-shadow: inset 16px 16px black;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.paragraph {
  .loader {
    // top: 30%;
    position: relative;
    // left: 50%;
    margin: auto;
  }
  .toogleLabel {
    // color: #87b7ff;
    // text-align: center;
    // font-family: Poppins;
    // font-size: 14px;
    // font-style: normal;
    // font-weight: 500;
    // line-height: normal;
    // margin-bottom: 10px;
    color: #1e1c80;
    text-align: center;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .toogle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10rem;
    margin-right: -3.5rem;
  }
}

.checked-img {
  top: 3rem;
  left: -2.9rem;
  position: absolute;
}
