.table-container {
  border: 2px solid #1e1c80;
  border-radius: 16px 16px 16px 16px;
  padding: 0px;
  margin-top: 10px;
  overflow: hidden;
}
.table {
  width: 100%;
  border-collapse: collapse;
  backdrop-filter: blur(2px);
  overflow: auto;
  border-collapse: separate;
  border-spacing: 0px 1px;
}

.table th,
.table td {
  border: none;
  
  text-align: center;
  color: #1e1c80;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.dummyTd{
  border: none; 
  text-align: center;
  color: #1e1c80;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  // font-weight: 600; 
  line-height: normal;
}

tbody{
  padding-top: 10px;
}
.table td {
  color: #1e1c80;
  padding: 8px;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.table th {
  font-weight: 400;
  font-size: 16px;
  // border-top: 1px solid #1E1C80;
  border-bottom: 1px solid #1E1C80;
  padding: 14px;
}

.table tr:nth-child(even) {
  //   background-color: #f2f2f2;
}

.table tr:hover {
  //   background-color: #ddd;
}

.menu-icon {
  margin-left: 1rem;
}

.menu {
  width: 120px;
  background-color: #3b37ff;
  border-radius: 8px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 5px;
  color: white;
  // left: 0rem;
  // top: 0;
  position: absolute;
  font-family: Poppins;
  font-size: 14px;
  left: 11rem;
  top: -13px;
}

.show-menu {
  display: block;
}

.view-btn {
  color: #3B37FF;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  // background: #3b37ff;
  border: none;
  // padding: 6px 12px;
  // border-radius: 18px;
  // border-radius: 10px;
  cursor: pointer;
}

.await-button {
  color: #1e1c80;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.loader-sentence {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  animation: spin 1s linear infinite;
  top: 0;
  left: -274px;
}
.loader-sentence.compare {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
}
.loader-glossary {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  animation: spin 1s linear infinite;
  top: 0;
  left: -274px;
}
.latest-para {
  display: inline-block;
  left: 200px;
  position: static;
  width: 10px;
  height: 10px;
  border-width: 2px;
  margin-right: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.download-icon-dashboard {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 1rem;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table caption {
    font-size: 1.3em;
  }
  
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    // border-bottom: 1px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  table td {
    // border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    text-align: right;
    padding: 10px !important;
  }
  
  table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}

.bookTitle{
  min-width: 24rem;
 
}

.bookAuthor{
 min-width: 21rem;


}




.MuiList-root {
  border: none !important;
  border-radius: 5px;
}
// }

@media screen and (max-width: 1100px) {
  .bookTitle{
    min-width: 10rem;
   
 }
 
 .bookAuthor{
   min-width: 10rem;
  
 
 }
}


@media screen and (max-width: 1500px) {
  .bookTitle{
    min-width: 10rem;
   
 }
 
 .bookAuthor{
   min-width: 10rem;
  
 
 }
}

@media screen and (min-width: 1600px) {
  .bookTitle{
    min-width: 25rem;
   
 }
 
 .bookAuthor{
   min-width: 25rem;
  
 
 }
}