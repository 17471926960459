:root {
  --switch-color: #1e1c80;
  --switch-width: 40px;
  --switch-height: 20px;
  --switch-btn-size: 20px;
  --gap: 2px;
}

/* Styles */
.toggle {
  cursor: pointer;
  display: inline-block;
}

.toggleSwitch {
  display: inline-block;
  background: #ccc;
  border-radius: 16px;
  width: var(--switch-width);
  height: calc(var(--switch-height) + var(--gap) * 2);
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;
}

.toggleSwitch:before,
.toggleSwitch:after {
  content: "";
}

.toggleSwitch:before {
  display: block;
  background: linear-gradient(to bottom, #fff 0%, #eee 100%);
  border-radius: 50%;
  // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  width: var(--switch-btn-size);
  height: var(--switch-btn-size);
  position: absolute;
  top: var(--gap);
  left: var(--gap);
  transition: left 0.25s;
}

.toggle:hover .toggleCheckBox:enabled + .toggleSwitch:before {
  background: linear-gradient(to bottom, #fff 0%, #fff 100%);
  // box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
}

.toggleCheckBox:checked + .toggleSwitch {
  background: var(--switch-color);
}

.toggleCheckBox:checked + .toggleSwitch:before {
  left: calc((var(--switch-width) / 2) - var(--gap));
}

.toggleCheckBox {
  position: absolute;
  visibility: hidden;
}

.toggleLabel {
  margin-left: 5px;
  position: relative;
  top: var(--gap);
}

.toggleCheckBox:disabled + .toggleSwitch {
  opacity: 0.4;
}

.toggleCheckBox:disabled + .toggleSwitch + .toggleLabel {
  opacity: 0.3;
}

.toggleDisabled {
  pointer-events: none;
  cursor: default;
}

