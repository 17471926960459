.back-arrow-icon {
  color: #3b37ff;
  width: 28px;
  height: 28px;
}

.ck-editor {
  // min-height: calc(100dvh - 52px - 42px - 50px) !important;
  // height: calc(100dvh - 52px - 42px - 50px) !important;
  display: block;
  // overflow: scroll;
}

.book-editor-container {
  min-height: calc(100dvh - 132px);
}

.individual-scroll.book-editor-container .book-editor-content {
  height: calc(100dvh - 132px);
}
.individual-scroll.book-editor-container #source .ck-editor__editable,
.individual-scroll.book-editor-container #translated .ck-editor__editable {
  height: auto;
}

.book-editor-content {
  min-height: calc(100dvh - 132px);
}
//  'document_size': {'width': 402.67, 'height': 636}, 'document_margins': {'top': 30.67, 'bottom': 18.67, 'left': 49.13, 'right': 49.13}

#source .ck-editor__editable,
#translated .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: var(--hc-cke-width, 402.67px);
  max-width: var(--hc-cke-width, 402.67px);
  min-height: var(--hc-cke-height, calc(100dvh - 39px - 42px - 52px - 48px - 48px));

  /* Keep the "page" off the boundaries of the container. */
  // margin: 30.67px 18.67px 49.13px 49.13px;
  padding-left: var(--hc-cke-margin-left);
  padding-right: var(--hc-cke-margin-right);
  padding-top: var(--hc-cke-margin-top);
  padding-bottom: var(--hc-cke-margin-bottom);
  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  // margin: 0 auto;
  overflow: hidden;

  // height: 100%; 
  transform-origin: top left;
}

#source .ck-editor__editable {
  transform:scale(var(--hc-cke-source-scale,1));
  height: var(--hc-cke-sourced-height,"none");
  min-height: var(--hc-cke-editors-minHeight,var(--hc-cke-height, calc(100dvh - 39px - 42px - 52px - 48px - 48px)));
}

#translated .ck-editor__editable {
  transform:scale(var(--hc-cke-translated-scale,1));
  height: var(--hc-cke-translated-height,"none");
  min-height: var(--hc-cke-editors-minHeight,var(--hc-cke-height, calc(100dvh - 39px - 42px - 52px - 48px - 48px)));
}

.hideSourceScrollBar::-webkit-scrollbar,
.hideTranslatedScrollBar::-webkit-scrollbar{
  display: none;
}

.book-editor-body {
  height: calc(100dvh - 52px - 42px - 39px) !important;
}

// Removing left and right border from the ckeditor toolbar
#translation-editor-toolbar .ck.ck-toolbar {
  border: 0;
  border-top: 1px solid var(--ck-color-toolbar-border);
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.ck-editor__top {
  position: fixed !important;
  top: 6.5rem;
  width: 100% !important;
  left: 0;
  max-height: 39px !important;
}

.ck.ck-editor__editable_inline {
  font-family: "Times New Roman", Times, serif;
  padding: 2em;
}

.ck.ck-toolbar > .ck-toolbar__items {
  justify-content: center;
  column-gap: 20px;
}

.ck.ck-editor__editable_inline > p {
  margin: 0;
  outline-color: #e2f1ff99;
}

.ck.ck-reset_all {
  max-height: 39px;
}

.ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
  min-height: calc(100dvh - 52px - 42px - 50px - 54.46px) !important;
}



.highlighted {
  background-color: #e2f1ff !important;
  border-radius: 10px;
  transition: all 500ms ease-in;
  // transition-delay: 100ms;
  outline: 10px solid #e2f1ff99;
}

#translated > .ck-editor__editable > p:hover {
  transition: all 200ms ease-in;
  border-radius: 10px;
  background-color: #e2f1ff !important;
  outline: 10px solid #e2f1ff99;
}

.ck .ck-font-size-dropdown .ck-dropdown__panel .ck-list{
  max-height: 18rem !important;
  overflow-y: scroll !important;
  width:fit-content;
}


.ck .ck-font-size-dropdown .ck-dropdown__panel .ck-list::-webkit-scrollbar {
  width: 7px;
}

.ck .ck-font-size-dropdown .ck-dropdown__panel .ck-list::-webkit-scrollbar-track{
  background:#F1F1F1

}
.ck .ck-font-size-dropdown .ck-dropdown__panel .ck-list::-webkit-scrollbar-thumb {
  background: #C1C1C1;
  border-radius: 10px;
} 

.ck .ck-font-size-dropdown .ck-dropdown__panel .ck-list::-webkit-scrollbar-thumb:hover {
  background: #949494;
  border-radius: 10px;
} 

.ck .ck-font-size-dropdown .ck-dropdown__panel .ck-list .ck-list__item{
  width: auto !important;
  min-width: 0em !important;
}


.ck.ck-toolbar>.ck-toolbar__items .ck-button:not(.ck-disabled){
  cursor: pointer;
}

.ck.ck-content span.small-caps {
  font-variant: small-caps;
}


.ck .ck-find-and-replace-form__actions .replaceOverBookButton{
  color:rgb(76,76,76);
  opacity:0.5;
  padding:0.18em 0.5em;
  border-radius: 2px;
}

.ck .ck-find-and-replace-form__actions .replaceOverBookButton:focus{
  border: 1px solid #2977ff;
  outline: 3px solid rgba(119, 186, 248, 0.8);
}

.ck .ck-find-and-replace-form__actions .enableHover:hover{
 background-color: #f0f0f0;
}


.translatecountBookOver{
  width: 100% !important;
  // border: 2px solid;
  display: flex !important;
  justify-content: space-between;
  margin-top: 10px  !important;
  margin-left: 0px !important;
  padding: 0px 4px !important;
}

.sourcecountBookOver{
  width: 100% !important;
  // border: 2px solid;
  display: flex !important;
  justify-content: space-between;
  margin-top: 10px  !important;
  margin-left: 0px !important;
  padding: 0px 4px !important;
}

.totalMatchesCount{
  color:rgb(76,76,76) !important;
}

.chapterNavigations{
  display: flex;
  gap: 19px;
  margin-bottom: 5px;
}

.backArrowButton{
  // background-color: #f9f9f9 !importan;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer;

}

.totalCount{
  font-size: 12px !important;
  color: #9f9f9f !important;
}

.nextArrowButton{
  // background-color: #f9f9f9;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
}

[data-cke-tooltip-text="Glossary"] {
  border: none !important;
  outline: none !important;  /* Remove the focus outline */
  box-shadow: none !important; /* Remove any default box shadow */
}

[data-cke-tooltip-text="Glossary"]:focus {
  outline: none !important; /* Ensure no outline on focus */
  box-shadow: none !important; /* Ensure no box shadow on focus */
  border: none;
}

.glossarySuggestionAlert{
  position: absolute !important;
  width: 6.7px !important;
  height: 7px !important;
  border-radius: 50% !important;
  right: 2px !important;
  top: 4px !important;
  background-color: #CF1322 !important;
}

@media screen and (max-width: 600px) {
  
}

.hct-book-editor__container {
  --hct-app-editor-height: calc(100dvh - 133px);
  height:var(--hct-app-editor-height)
}

.hct-app-editor-border{
  
}


.middleLine{
  border-right: 1px solid var(--ck-color-base-border);
 
}

