

#notTranslatedBox{
    // width: calc(var(--hc-cke-width, 402.67px) - 30px);
    // max-width: calc(var(--hc-cke-width, 402.67px) - 30px);
    // min-height: calc(var(--hc-cke-height, calc(100dvh - 39px - 42px - 52px - 48px - 48px)-100px));
    border-radius: var(--ck-border-radius);
    left: 50%;
    transform: translate(-50%, -50%);
    top:12rem;
    background: white;
    margin: 0 auto;  
    z-index: 10;
  }

  #notTranslatedBox p {
    margin : 0 !important;
  }
  


.translateNowButton {
    display: flex;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #3B37FF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 21rem;
    border-radius: 4.5px;
    border: 0.6px solid #3B37FF;
    background-color: transparent;
    outline: none;
    margin-top: 10px;
    cursor: pointer;

}

.ReviewPreviousButtonChapter{
    display: flex;
    padding: 9px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 21rem;
    border-radius: 4.5px;
    background: #3B37FF;
    outline: none;
    border: none;
    cursor: pointer;

}

.boxHeading{
  display: flex;
  align-items: center;
  width: 21rem;
  justify-content: center;
  column-gap: 4px;
  font-size: 15px;
  color: #CA0000;
}

.boxPara{
  font-size: 12px;
  margin-top: -10px;
  width: 22rem;
  text-align: center;

}