.card {
  width: 250px !important;
  height: 102px !important;
  border-radius: 16px;
  background: #fff;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.16);
  padding: 20px;
  flex-basis: 10%;
  margin: 10px 0px;
  overflow: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-title {
  width: max-content;
  color: #1e1c80;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.card-value {
  color: #3b37ff;
  text-align: center;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media (max-width: 1530px) {
  .card {
    // width: 125px;
    width: 200px;
    height: 75px;
    padding: 10px;
  }
}

@media (max-width: 1200px) {
  .card {
  }
}

@media (max-width: 1024px) {
  .card {
  }
}

@media (max-width: 768px) {
  .card {
  }
}

@media (max-width: 480px) {
  .card {
  }
}
