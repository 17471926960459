.makingUserTypeTheWordFromEnglishParagraphSectionHeading, 
.askingUserIfHeWantsToAddVariantToGLossaryQuestion
{
    margin-bottom: 35px;
}
.makingUserTypeTheWordFromEnglishParagraphSectionHeading {
    font-size: 18px;
    /* background-color: white; */
}
.askingUserIfHeWantsToAddVariantToGLossaryYesButton,
.askingUserIfHeWantsToAddVariantToGLossaryYesButton:hover {
    background-color: #1e1c80 !important;
}
.addToGlossaryButton,
.addToGlossaryButton:hover
{
}
.addToGlossaryButtonWhenNotDisabled, 
.addToGlossaryButtonWhenNotDisabled:hover
{
    background-color: #1e1c80 !important;

}
.askingUserIfHeWantsToAddVariantToGLossaryNoButton
{
    color: #1e1c80 !important;
}
.makingUserTypeTheWordFromEnglishParagraphInputSection {
    position: relative;
}
.makingUserTypeTheWordFromEnglishParagraphInputSectionInputField {
    padding:10px;
    border-radius: 10px;
    width: 100%
}
.makingUserTypeTheWordFromEnglishParagraphInputSectionSuggestionList {
    position: absolute;
    width: 100%;
    top: 37px;
    background-color: white;
    z-index: 1;
    overflow: auto;
    max-height: 100px;
    border-radius: 10px;
}
.makingUserTypeTheWordFromEnglishParagraphInputSectionSuggestionListItem {
    padding: 10px;
    width: 100%;
    /* list-style-type: do; */
    cursor: pointer;
}
.addToGlossaryButton {
    margin-top: 80px !important;
    /* margin-bottom: 81px; */
}
.variantFlossaryStringInputFieldErrorMessage {
    color: red;
}
.visibilityHidden {
    visibility: hidden;
}
.makingUserTypeTheWordFromEnglishParagraphSection {
    /* width: 450px; */
    width: 412px;
    padding: 20px;
}
.variantFlossaryStringInputFieldErrorMessage {
    height: 19px;
}
.addToGlossaryButtonDisabled {

}
.addToGlossaryButtonRow {
    width: 100%;
    text-align: center;
}
.askingUserIfHeWantsToAddVariantToGLossarySection {
    padding: 20px;
}