

/* Variables */
:root {
  --app-button-primary-color-code: 30, 28, 128;
  --app-button-on-primary-color: #fff;
  --app-button-border-radius: 8px;
  --app-button-contained-padding: 0.8rem 1rem;
}

/* Styles */
.button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  position: relative;
  overflow: hidden;
  outline: none;
  border: none;
  background: none;
}

.btnPrimary {
  color:  rgba(var(--app-button-primary-color-code),1);
  padding: 0.25rem 0.5rem;
  border-radius: 6px;
  transition: background 0.25s;
}

.btnPrimary:hover {
  background-color: rgba(var(--app-button-primary-color-code) , 0.1);
}

.btnPrimaryDisabled {
  background: none;
}

.btnPrimaryContained {
  background-color: rgba(var(--app-button-primary-color-code),  1);
  color: var(--app-button-on-primary-color);
  border-radius: var(--app-button-border-radius);
  padding:var(--app-button-contained-padding); 
  transition: background 0.25s;
}
.btnPrimaryContained:hover{
  background-color: rgba(var(--app-button-primary-color-code),  0.9);
}

.btnDisabled {
  pointer-events: none;
  cursor: default;
}

.btnPrimaryContainedDisabled {
  border-radius: var(--app-button-border-radius);
  padding:var(--app-button-contained-padding); 
  color: rgba(0, 0, 0, 0.26);
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12);
}

.btnPrimaryOutlined {
  background-color: rgba(var(--app-button-primary-color-code),1);
  border-radius: var(--app-button-border-radius);
  border: 1px solid var(--app-button-border-radius)
}
