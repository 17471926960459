.paragraph-container {
    margin-top: 7.5rem;
    text-align: center;
    min-height: 110vh;
  }
  
  .chapter {
    margin-bottom: 1rem;
  }
  
  .paragraph-translate {
    display: flex;
    padding: 1rem;
    margin: 0 auto 1rem;
    width: 80vw;
    background-color: rgba(255, 255, 255, 0.5);
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    min-height: 149px;
    flex-wrap: wrap;
  
    @media (min-width: 320px) and (max-width: 768px) {
      width: 60vw;
    }
  }
  
  .right {
    right: 0;
  }
  .justify-center {
    // display: flex;
    // justify-content: right;
  }
  .dropdown-translate-modal {
    padding: 10px;
    border-radius: 8px;
  }
  
  .english-paragraph,
  .dutch-paragraph {
    flex: 1;
    padding: 0 1rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  
  .english-paragraph-inPalatteMode {
    flex: 1;
    padding: 0 1rem;
    position: relative;
  }
  
  .dutch-paragraph {
    // display: flex;
    // flex-direction: row-reverse;
  }
  
  .dutch-paragraph.wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
  
  .paragraph-sentence {
    text-align: left;
    width: 100%;
    margin: 0;
  }
  
  // .title-header {
  //   margin-bottom: 7px;
  // }
  
  .title-header {
    margin-bottom: 1rem;
    color: #1e1c80;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px 0 10px 0;
  }
  
  .like-button-translate {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    // top: 3.8rem;
    top: 3.5rem;
    right: -3rem;
    transform: translateY(-50%);
  }
  
  .like-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .merge-icon {
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .checkbox {
    display: flex;
    position: absolute;
    transform: translateY(-50%);
    top: 3rem;
    left: -3rem;
  }
  .paraNoWrapper {
    display: flex;
    position: absolute;
    transform: translateY(-50%);
    top: 1rem;
    left: -3rem;
  }
  .paraNoWrapper_content {
    width: 24px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  input[type="checkbox"] {
    display: grid;
    place-content: center;
    width: 20px;
    height: 20px;
    accent-color: #ffff;
    opacity: 0.2;
  }
  
  input[type="checkbox"]::before {
    content: "";
    width: 16px;
    height: 16px;
    transform: scale(0);
    box-shadow: inset 16px 16px black;
    opacity: 0.2;
  }
  
  input[type="checkbox"]:checked::before {
    transform: scale(1);
    opacity: 0.2;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 16px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .paragraph {
    .loader {
      position: relative;
      margin: auto;
    }
  }
  
  #textarea-active {
    color: #87b7ff;
    background-color: #3b37ff;
    border-color: #3eb05e;
    border-radius: 1em;
    margin-top: -10px;
  }
  
  .paragraph-textarea {
    width: 40rem;
    height: 150px;
  }
  
  .confirm-translate {
    margin-bottom: 2rem;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 187px;
    height: 47px;
    border: none;
    border-radius: 10px;
    background-color: #959595;
    cursor: pointer;
  }
  
  .dutch-paragraph-human-translated {
    flex: 1;
    padding: 0 1rem;
  }
  
  .dutch-paragraph-human-translated {
    // display: flex;
    // flex-direction: row-reverse;
  }
  
  .para-header-accuracy {
    color: gray;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    //margin-top: -15px;
    position: relative;
    // position: absolute;
    // bottom: 3px;
    width: auto;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-end;
  }
  
  .pair-percentage {
    margin-right: 5px;
    margin-top: 10px;
    color: #1e1c80;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  
  .edit-active {
    width: auto;
  }
  
  .confirm-post-btn {
    // position: absolute;
    // bottom: 0;
    // position: fixed;
    // bottom: 4rem;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 16px;
    background: #3b37ff;
    cursor: pointer;
    border: none;
    // padding: 1rem;
    height: 47px;
    // left: 0;
    // right: 0;
    width: 180px;
    margin-top: 40px;
    // left: 45%;
    // z-index: 99;
  }
  .merge-sent-btn {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 16px;
    background: #3b37ff;
    cursor: pointer;
    border: none;
    height: 40px;
    width: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  
  .merge-sent-btn-disable {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 16px;
    background: #b6b6b9;
    cursor: pointer;
    border: none;
    height: 40px;
    width: 100px;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  
  .fetch-btn {
    margin-left: 80px;
    margin-top: 0px;
  }
  
  .glossary {
    position: absolute;
    color: #3b37ff;
    text-align: center;
    display: flex;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    //padding: 0.5rem;
    //height: 137px;
    width: auto;
    align-items: center;
    justify-content: center;
    z-index: 8;
    top: 45px;
    left: 47%;
  }
  
  .glossary:hover {
    cursor: pointer;
  }
  
  .left-column,
  .right-column {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
  }
  
  .paragraph-translate-extended {
    display: flex;
    padding: 1rem;
    margin: 0 auto 1rem;
    width: 70vw;
    background-color: rgba(255, 255, 255, 0.5);
    position: relative;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    min-height: 200px;
    flex-wrap: wrap;
    flex-direction: column;
    // justify-content: space-between;
  
    @media (min-width: 320px) and (max-width: 768px) {
      width: 60vw;
    }
  }
  
  .btn-translate {
    text-align: "left";
    width: "100%";
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  
  button.confirm-post-btn.helpful {
    background: #3eb05e;
    border-radius: 7px;
    width: 150px;
    height: 40px;
    margin-top: 0px;
    margin-right: 20px;
  }
  
  button.confirm-post-btn.not-helpful {
    background: #ee2424;
    border-radius: 7px;
    width: 150px;
    height: 40px;
    margin-top: 0px;
  }
  
  .feedback {
    margin-bottom: 1rem;
    position: absolute;
    z-index: 99;
    left: 585px;
    bottom: 18px;
  }
  
  .feedback p {
    margin-top: 0px;
    color: #1e1c80;
    margin-bottom: 16px;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
  }
  
  .parent_feed {
    position: relative;
    height: 110px;
  }
  
  .edit-feedback-wrapper {
    // position: absolute;
    border: 1px solid black;
    width: auto;
    height: 25px;
    top: -19px;
    z-index: 99;
    border-radius: 4px;
    background-color: white;
    padding: 2px;
  }
  
  .pencil-tick-icon {
    margin-bottom: 0px;
    margin-top: 3px;
  }
  
  .sentence-editing-icon {
    margin-bottom: 0px;
    margin-top: 3px;
    margin-left: 10px;
  }
  
  .edit-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    // margin-top: -30px;
  }
  
  .feedback-container {
    width: 180px;
    max-height: 322px;
    border: 1px solid #1e1c80;
    padding: 10px;
    position: absolute;
    z-index: 999;
    background: #fff;
    border-radius: 8px;
    top: -45px;
    left: 60px;
  }
  
  .feedback-list {
    display: block;
    margin-bottom: 8px;
    color: #1e1c80;
  }
  
  .feedback-checkbox {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 8px;
    border-radius: 3px;
    border: 1px solid #1e1c80;
  }
  
  .slider-wrapper {
    display: flex;
    flex-basis: 100%;
    justify-content: center;
    align-items: center;
  }
  
  .submit-btn {
    margin-top: 0;
    flex-basis: 15%;
  }
  
  .accuracy {
    margin-right: 5px;
    margin-top: 15px;
    color: #1e1c80;
    width: 100%;
    display: flex;
  }
  
  .translation-score {
    color: #1e1c80;
    flex-basis: 15%;
    white-space: nowrap;
    margin-bottom: 7px;
  }
  
  .dashboard-download-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .generate_latest_para {
    display: block;
    font-size: 12px;
    background: #fff3d1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ebeb17;
    margin-top: 10px;
  }
  .generate_latest_para.error {
    display: block;
    font-size: 12px;
    background: #ffd1d1;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #d53b3b;
    margin-top: 10px;
  }
  
  .paragraph-loading {
    display: block;
    font-size: 12px;
    margin-top: 10px;
    background: #d4d3f5;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #7f7bf7;
  }
  .VariantCard{
    max-height: 163px;
    overflow-y: scroll;
  }
  
  .VariantCard::-webkit-scrollbar {
    width: 5px;
  }
  
  .VariantCard::-webkit-scrollbar-thumb {
    background-color: #888;
  }
  
  .glossary_Box {
    border-top: 1px solid #a4a4a4;
    width: 100%;
    margin-top: 48px;
    display: flex;
    // align-items: center;
    justify-content: center;
    padding-top: 5px;
    height: 250px;
  
    p {
      color: #9d9d9d;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 0;
      margin-bottom: 7px;
  
      span {
        margin-right: 5px !important;
      }
    }
  
    .glossary_Left {
      width: 49%;
  
      h5{
        color: #727272;
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        margin: 0px;
      }
  
      ul{
        padding: 0px;
        text-align: left;
        margin: 5px 0px;
        li{
          display: inline-block;
          list-style-type: none;
          font-size: 14px;
          font-weight: 400;
          color: #000;
          margin-right: 15px;
          span{
            display: inline-block;
            height: 20px;
            width: 20px;
            border-radius: 100px;
            border: 1px solid #000;
            margin: -2px 10px 0px 0px;
            vertical-align: middle;
          }
        }
        li:last-child{
  margin-right: 0px;
        }
      }
  
  
      .glossary_content{
        max-height: 211px;
        overflow-y: scroll;
      }
  
      button {
        border-radius: 32px;
        background: #3b37ff;
        border: none;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 6px 8px;
        cursor: pointer;
        margin-top: 20px;
      }
  
      .AddKeywords {
        background: transparent;
        color: #3b37ff;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
      }
  
      p {
        span {
          border-radius: 25px;
          border: 1px solid #000;
          background: #e1f9ff;
          color: #000;
          text-align: left;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 6px 12px;
          margin: 10px 0;
          display: inline-block;
          width: auto;
          position: relative; /* Ensure positioning context for pseudo-element */
      }
      
      /* Define cross icon */
      // span::after {
      //     content: '\00D7'; /* Unicode for '×' (cross symbol) */
      //     position: absolute;
      //     color: red;
      //     border: 2px;
      //     top: -20%;
      //     right: -2px;
      //     border-radius: 10px;
      //     transform: translateY(-50%);
      //     opacity: 0; /* Initially hide the cross icon */
      // }
      
      // /* Show cross icon on hover */
      // span:hover::after {
      //     opacity: 1;
      //     cursor: pointer; /* Show pointer cursor on hover */
      // }
        input {
          border-radius: 32px;
          border: 1px solid #000;
          background: #e1f9ff;
          color: #000;
          text-align: left;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 6px 12px;
          margin: 10px 0;
          display: inline-block;
          width: auto;
        }
  
        input {
          width: 100px;
        }
      }
      .inputBox {
        position: relative;
        display: inline-block;
        width: auto;
  
        span {
          position: absolute;
          border: none;
          background: transparent;
          right: 0;
          top: 1px;
          cursor: pointer;
        }
      }
    }
  
    .glossary_Left::-webkit-scrollbar {
          width: 5px;
      }
  
      .glossary_Left::-webkit-scrollbar-thumb {
          background-color: #888;
      }
  
      .glossary_Left > p {
        margin-right: 80px;
      }
  
      .glossary_Left > button {
        margin-right: 80px;
      }
  
    .glossary_Right {
      width: 48%;
      border-left: 1px solid #a4a4a4;
      padding-left: 15px;
      height: 245px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      flex-direction: column;
  
      h2 {
        color: #1e1c80;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-align: left;
        display: flex;
        position: absolute;
        left: 20px;
        top: -15px;
        align-items: baseline;
        width: 100%;
  
        span {
          color: #9d9d9d;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-left: 10px;
        }
  
        label {
          color: #000;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
  
          span {
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background-color: #ffc727;
            padding: 3px 10px 2px 10px;
            position: relative;
            display: inline-block;
            height: 19px;
            margin-left: 20px;
          }
          span:before {
            content: "";
            position: absolute;
            top: 50%;
            left: 0%;
            transform: translate(-50%, -50%) rotate(45deg);
            background: #ffc727;
            height: 17px;
            width: 16px;
          }
          .PinkColor {
            background-color: #ffc0c0;
          }
          .PinkColor:before {
            background-color: #ffc0c0;
          }
          .BlueColor {
            background-color: #87b7ff;
          }
          .BlueColor:before {
            background-color: #87b7ff;
          }
        }
  
        p {
          justify-content: center;
          align-items: center;
        }
      }
  
      .Variants {
        display: inline-block;
        vertical-align: top;
        width: 100%;
        button {
          border-radius: 8px;
          border: 1px solid #3b37ff;
          color: #3b37ff;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: 143px;
          height: 30px;
          margin-right: 5px;
          margin-left: 10px;
          background-color: transparent;
          cursor: pointer;
        }
  
        label {
          margin-left: 6px;
        }
  
        .VariantsBox {
          margin-bottom: 8px;
          margin-left: 10px;
          display: flex;
          align-items: center;
  
          input {
            height: 16px;
            width: 16px;
            cursor: pointer;
          }
        }
  
        .Applyto {
          border-radius: 8px;
          border: 1px solid #999898;
          color: #999898;
        }
        .ApplytoEnable {
          border-radius: 8px;
          border: 1px solid #3b37ff;
          color: #3b37ff;
        }
      }
  
      .TypeInput {
        display: flex;
        margin-bottom: 15px;
        margin-top: 5px;
        margin-left: 15px;
  
        input {
          color: #999898;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: 100px;
          border: none;
          border-bottom: 1px solid #3b37ff;
          margin-right: 10px;
          margin-left: 5px;
          outline: none;
        }
      }
    }
  }
  
  .AccuracyBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 60px;
  
    .accuracy {
      width: 200px;
      margin: 0;
    }
  
    .glossary {
      position: relative;
      top: 0;
      left: 0;
      margin: 0;
    }
  
    .confirm-post-btn {
      margin: 0;
    }
  }
  
  .EditBtn {
    display: inline-block;
    position: relative;
    top: 5px;
    left: 15px;
  
    img {
      margin-right: 10px;
    }
  }
  .ApplyBtnDiv {
    position: relative;
    .dropdown {
      z-index: 1;
      border: 1px solid #1e1c80;
      width: 340px;
      position: absolute;
      background-color: #ffff;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      top: 40px;
      ul {
        padding: 0px 10px;
        margin: 0px;
        li {
          display: block;
          list-style-type: none;
          border-bottom: 1px solid #000;
          padding: 5px;
          p {
            font-size: 14px;
            color: #3b37ff;
            text-decoration: none;
            cursor: pointer;
          }
        }
        li:last-child {
          border: none;
        }
      }
    }
  }
  
  .feedback1 {
    left: 50px;
  }


.dutch-paragraph.wrapper p {
    display: inline;
}
  