@import "../../variables.scss";

.textarea-container {
  position: relative;
  width: 41rem;
}

.textarea {
  // border: 1px solid #000;
  border: none;
  // padding: 12px;
  padding: 0px;
  width: 100%;
  color: #000;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;  font-size: 16px;
  font-style: normal;
  // font-weight: 400;
  // line-height: normal;
  // transition: all 0.3s;
  // resize: vertical;
  //max-height: 300px;
  overflow: visible;
  resize: none;
}

.textarea::placeholder {
  color: #c8cbce;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.textarea-label {
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  pointer-events: none;
  color: #c8cbce;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
  transition: all 0.3s;
  background: transparent;
}

.textarea.active + .textarea-label {
  top: -12px;
  font-size: 14px;
  opacity: 1;
  color: #1e1c80;
  padding: 0 3px 0 3px;
  left: 15px;
  right: unset;
  background: #ffff;
}

.textarea:focus-visible {
  outline: none;
}

.icons-container {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: -26px;
}

.icon {
  cursor: pointer;
  margin-left: 5px;
}

.close-icon {
  width: 20px;
  height: 20px;
  //   fill: #1e1c80;
}

.tick-icon {
  width: 20px;
  height: 20px;
  //   fill: #1e1c80;
}
