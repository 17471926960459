.book-header-container {
  .pagination {
    display: flex;
    font-size: 18px;
    justify-content: space-around;
    img {
      width: 14px;
      cursor: pointer;
    }
    .pagination-content {
      .pagination-page-count {
        font-size: 14px;
        line-height: 0.5;
      }
      .pagination-page {
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 0.1;
      }
      // p {
      //   font-size: 18px;
      //   line-height: 0.1;
      // }
    }
  }
}


.sourceFindButton button:hover{
  background-color: transparent !important;
}

.sourceFindButton .ck-on{
  background-color: transparent !important;
}

.sourceFindButton button:focus{
  border: none !important;
  box-shadow: none !important ;
}


.translateFindAndReplaceButton button {
  color:#1e1c80 !important;
  font-size: 12px !important;
  line-height: 1.25rem !important;
  cursor: pointer !important;
}

.translateFindAndReplaceButton button:hover{
  background-color: transparent !important;
}

.translateFindAndReplaceButton .ck-on{
  background-color: transparent !important;
}

.translateFindAndReplaceButton button:focus{
  border: none !important;
  box-shadow: none !important ;
}
