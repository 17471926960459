@import "../../variables.scss";

.container-pair-page {
  .header {
    display: flex;
    justify-content: space-between;
    color: #1e1c80;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-items: center;
    width: 100vw;
    padding-right: 1rem;
  }
  .content-header {
    color: #1e1c80;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 0.7;
  }
  .header-title {
    color: #1e1c80;
    font-family: Poppins;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-top: 20px;
    margin-left: 24px;

    @media (max-width: 768px) {
      font-size: 32px;
    }

    @media (max-width: 480px) {
      font-size: 24px;
    }
  }

  .header-title-edit {
    color: $primary-color;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    margin: 0;
    margin-top: 26px;
  }

  .back-arrow {
    font-size: 16px;
    font-weight: bold;
    margin-right: 2rem;
    cursor: pointer;
  }

  .content {
    display: flex;
    align-items: center;
    padding: 0 2rem 0 2rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
    }
  }

  .book-detail {
    flex: 1;
    color: #3979d8;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 0.7;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .preview-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background: #87b7ff;
    color: #dae8ff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media (max-width: 768px) {
      font-size: 20px;
      margin-top: 1rem;
    }
  }

  .eye-icon {
    font-size: 24px;
  }

  .line-container {
    padding: 0 2rem 0 2rem;

    .line {
      border-top: 4px solid #224e8e;
      margin-top: -0.7rem;
    }

    .title {
      color: #1e1c80;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .container-pairs {
    background: #98b3de;
    padding-bottom: 1rem;

    .container-pairs-header {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #ffff;
      height: 60px;
      //   opacity: 0.5;
      margin-bottom: 1rem;

      p {
        color: #3979d8;
        text-align: center;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media (max-width: 768px) {
          font-size: 24px;
        }
      }
    }

    .pair-row {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #f0f0f0;
      height: 60px;

      p {
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }
}

.error-message {
  text-align: center;
  margin-top: 25%;
  font-size: 24px;
  color: red;
}

.user-menu {
  border-radius: 16px;
  background: #1e1c80;
  width: 303px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.user-menu-trans {
  border-radius: 16px;
  background: #1e1c80;
  padding-left: 30px;
  padding-right: 30px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;

}

.content-main-container {
  display: flex;
  align-items: center;
}

.hr-line {
  border-right: 2px solid blue;
  margin-right: 10px;
  margin-left: 10px;
  width: 2px;
  height: 48px;
}
