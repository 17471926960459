@import "../../variables.scss";

.upload-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  

  .upload-header-title {
    color: #1e1c80;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 2rem;
  }
}

.header-btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
// .button-container-single_batch{
//   margin-right: 1rem;
// }

.center-content {
  width: 100%;

  .header-title {
    color: $primary-color;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: 768px) {
      font-size: 36px;
    }

    @media (max-width: 480px) {
      font-size: 28px;
    }
  }

  .form-title-container {
    display: flex;
    align-items: end;
    justify-content: center;
    gap: 2rem;

    .form-book-title-sub-container {
      width: 100%;
      position: relative;
    }
  }

  .form-title {
    color: #1e1c80;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 24px;
    }

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }

  .file-upload-container {
   
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 1.7rem;
    margin-bottom: 30px;
    .upload-form {
      // width: 50%;
      width: 60vw;
      margin-bottom: 25px;
      .error-message {
        font-size: 12px;
        color: red;
        margin-bottom: 10px;
      }

      @media (max-width: 768px) {
        width: 90%;
      }
    }
  }
}

.form-title {
  font-size: 16px;

  @media (min-width: 640px) {
    font-size: 20px;
  }
}

.pair-by-container {
  margin-top: 20px;

  @media (max-width: 480px) {
    margin-top: 10px;
  }
}

.radio-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    justify-content: center;
  }
}

.radio-container label {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;

  @media (max-width: 480px) {
    margin-right: 15px;
  }
}

.radio-container input[type="radio"] {
  margin-right: 5px;

  @media (max-width: 480px) {
    margin-right: 3px;
  }
}

.radio-container span {
  color: #224e8e;
}

.radio-container input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 17px;
  width: 17px;
  border-radius: 50%;
  border: 2px solid #224e8e;
  outline: none;
  position: relative;
  cursor: pointer;

  @media (max-width: 480px) {
    height: 14px;
    width: 14px;
    border-width: 1px;
  }
}

.radio-container input[type="radio"]::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #224e8e;
  opacity: 0;
  transition: opacity 0.3s ease;

  @media (max-width: 480px) {
    width: 8px;
    height: 8px;
  }
}

.radio-container input[type="radio"]:checked::before {
  opacity: 1;
}

.btn-submit {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 10px;
  background: #b3b3b3;
  margin: auto;
  display: block;
  cursor: pointer;
  border: none;
  padding: 0.5rem 1rem 0.5rem 1rem;
  margin-top: 0.5rem;

  @media (min-width: 768px) {
    font-size: 30px;
  }

  @media (min-width: 480px) {
    font-size: 24px;
    margin-bottom: 2rem;
  }
}
.activeUpload {
  background-color: #3b37ff;
}
.upload-tooltip {
  display: flex;
  justify-content: start;
  align-items: center;
  color: #1e1c80;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 1rem;
  height: 3rem;
}

.upload-text {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-weight: 600;
}

.expand-button {
  text-decoration: underline;
}

.fill-info {
  font-size: 24px;
  color: #224e8e;
  cursor: pointer;
}

.tooltip-text {
  top: 0;
  right: 100%;
  margin-left: 2.4rem;
  padding: 6px 12px;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid #224e8e;
  border-radius: 4px;
  transition: opacity 0.3s ease;
  z-index: 9999;
}

.tooltip-text::before {
  content: "";
  position: absolute;
  margin-left: -3.2rem;
  border-width: 14px 39px 1px 0;
  border-style: solid;
  border-color: transparent #224e8e transparent transparent;
}

.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  // margin-top: 10%;
  // position: absolute;
}
.translate {
  margin-top: 10px;
}
.info-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.button-container {
  display: flex;
  // width: 100%;
  justify-content: center;
  margin-right: 1rem;
}

.button-container button {
  padding: 10px 20px;
  // font-size: 16px;
  border: none;
  cursor: pointer;
  // width: 20%;
  // width: 140px;
  width: 165px;
  color: #224e8e;
  text-align: center;
  font-family: Poppins;
  // font-size: 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #3b37ff;
}

#btn-batch {
  border-radius: 0 10px 10px 0;
}

#btn-single {
  border-radius: 10px 0 0 10px;
}

.button-container button.active {
  background-color: #3b37ff;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  // font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

// .upload-row {
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
// }

.advance-setting-item {
  display: flex;
  flex-direction: column;
}

.upload-row {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 10px;
}

.advance-setting-row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 40%;

  @media (max-width: 800px){
      width: 60%;
  }

  @media (max-width: 600px){
      width: 100%;
  }
}




.advance-setting-title {
  margin-right: 1rem;
  font-size: 1.17em;
  font-weight: 600;
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.advance-setting-label {
  margin-right: 1rem;
}

.upload-label {
  // width: 100px;
  // margin-right: 10px;
  margin-right: 1rem;
  font-size: 1.17em;
  font-weight: 600;
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
}
label.upload-label.to {
  margin-left: 16px;
}
.reference {
  margin-right: 0;
}

.upload-input {
  display: flex;
  align-items: center;
  width: 100%;
}
.uploadRef {
  margin-left: -4px;
}

.upload-input select {
  margin-right: 10px;
}

.dropdown-container {
  position: relative;
  // display: inline-block;
}

.dropdown-container select {
  background-color: white;
  border: 1px solid #1e1c80;
  border-radius: 10px;
  font: inherit;
  // line-height: 1.5em;
  line-height: 1.7em;
  // padding: 5px 5px 5px 5px;
  padding: 5px 5px 5px 10px;
  // width: 150px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.dropdown-container select.round {
  background-image: linear-gradient(45deg, transparent 50%, #ffff 50%),
    linear-gradient(135deg, #ffff 50%, transparent 50%), radial-gradient(#3b37ff 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px),
    calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  // width: 340px;
}

.dropdown-container select.round:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%), radial-gradient(#3b37ff 70%, transparent 72%);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  outline: 0;
}

.batch-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 5rem;
  gap: 50px;
}

.hint-batch-upload {
  color: #224e8e;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
}

.upload-container-header {
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-left: 1rem;
  height: 60px;
}

.btn-shw-dutch {
  background: none;
  border: none;
  color: #3b37ff;
  text-align: right;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  float: right;
  text-decoration-line: underline;
  cursor: pointer;
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .center-content {
    // margin-top: 5%;
  }

  .button-container button {
    // font-size: 28px;
  }
}

@media (min-width: 1200px) {
  .center-content {
    // margin-top: 8%;
  }

  .button-container button {
    // font-size: 32px;
  }
}

@media (max-width: 480px) {
  .center-content {
    margin-top: 5%;
  }

  .button-container {
    flex-direction: column;
    align-items: center;
  }

  .button-container button {
    font-size: 18px;
    width: 80%;
    border-radius: 5px !important;
  }

  // .upload-form {
  //   width: 86% !important;
   
  // }

  .form-title-container {
    flex-direction: column;
    gap: 10px;
  }

  .upload-input select {
    width: 100%;
    margin-right: 0;
  }

  .dropdown-container {
    margin-right: 0;
    // margin-top: -24px;
    // margin-bottom: 1rem;
  }

  .upload-label {
    width: 100%;
    margin-right: 0;
  }

  .hint-batch-upload {
    font-size: 14px;
  }

  .upload-container-header {
    margin-left: 0;
   
  }

  // .upload-row {
  //   flex-direction: column;
  //   align-items: center;
  //   gap: 10px;
  // }

  .upload-input {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.error-message-upload {
  position: absolute;
  top: 100%; /* Position it below the input */
  left: 0;
  font-size: 12px;
  color: red;
  display: none; /* Initially hide it */
}

.error-message-upload.show {
  display: block; /* Show when there's an error */
}
