.glossary-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;
    overflow: hidden;
  }
  
  .glossary-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
  
    // display: flex;
    // width: 300px;
    // height: 250px;
    // justify-content: center;
  }
  
  .glossary-message {
    margin-bottom: 10px;
    text-align: center;
  }
  
  .glossary-close {
    background-color: #f44336;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  