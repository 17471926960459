.react-flow__node {
  // width: 47% !important;
  display: flex;
  align-items: center;
  font-size: 15px !important;
  padding-left: 20px !important;
  border-radius: 4px !important;
  border: 1px solid #224e8e !important;
  background: #fff;
}

.main-header {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 100;
}
.container {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  width: 100%;

  .react-flow {
    height: 70vh !important;
    width: 100%;
  }
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  overflow: visible !important;
  line-height: 0.4375em !important;
  font-size: 14px !important;
}

.text-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffff;
  width: 100%;
  color: #1e1c80;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  //padding: 10px 0 10px 0;
  align-items: center;
}

.icon-header {
  align-items: center;
  display: flex;
  gap: 1rem;
  img {
    width: 20px;
    height: 20px;
  }
}

.react-flow__panel {
  display: none;
}

.react-flow__handle {
  border: 3px solid #3979d8 !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50px !important;
  background-color: #fff !important;
}

.react-flow__handle-right {
  right: -8px !important;
}

.react-flow__handle-left {
  left: -8px !important;
}

.container .react-flow {
  //   margin-bottom: 10rem;
}

.like-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 2.7rem;
  right: -3rem;
  transform: translateY(-50%);
}

.like-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-bottom: 10px;
}

// .pagination {
//   display: flex;
//   font-size: 18px;
//   justify-content: space-around;
//   img {
//     width: 14px;
//     cursor: pointer;
//   }
//   .pagination-content {
//     .pagination-page-count {
//       font-size: 14px;
//       line-height: 0.5;
//     }
//     .pagination-page {
//       font-size: 12px;
//       font-style: normal;
//       font-weight: 500;
//       line-height: 0.1;
//     }
//     // p {
//     //   font-size: 18px;
//     //   line-height: 0.1;
//     // }
//   }
// }

.extra-div {
  text-align: center;
  margin-top: 1rem;
  color: white;
  border: 1px solid black;
  width: 45%;
  margin-right: 20px;
  padding: 10px;
  float: right;
}

.merge-text-container {
  color: #3b37ff;
  height: 2rem;
  
  padding: 2px 8px 2px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border: 1px solid #3b37ff;
  border-radius: 16px;
  min-width: 125px;

  .merge-icon{
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .tick-circle{
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 70%;
    border-radius: 50%;
    background-color: white;
    cursor: pointer;
  }

}

.line {
  border-top: 4px solid #224e8e;
  width: 100%;
}
.confidence-rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 0.5px solid #1e1c80;
  width: 8.25rem;
  height: 2.75rem;
  border-radius: 1rem;

  .cr-child1-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 5px;

    .cr-child1 {
      padding: 2px;
      border: black 0.5px solid;
      border-top-left-radius: 10px;
      width: 30%;
      background-color: #fff3d1;
      font-size: 10px;
    }
    .cr-child2 {
      padding: 2px;
      border: black 0.5px solid;
      background-color: #ffc0c0;
      width: 30%;
      font-size: 10px;
    }
    .cr-child3 {
      width: 25%;
      cursor: pointer;
    }
  }
  .cr-child2-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 100;
  }
}
.book-view-container {
  // display: flex;
  // width: 50%;
  // justify-content: flex-start;
  // align-items: flex-start;
  // padding-left: 1rem;
}
.source-header-text {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.download-btn {
  color: #3b37ff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 16px;
  background: #fff;
  cursor: pointer;
  border: 1px solid #3b37ff;
  width: 163px;
  height: 48px;
  margin-right: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.download-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-bottom: 0px;
}

.form-chapter {
  margin-top: 15px;
}

.chapter-input {
    width: 24px;
    font-weight: 600;
    font-size: 13px;
    font-family: Poppins;
    color: #1E1C80;
    margin-top: 10px;
    text-align: center;
    border: 0.1px solid #1E1C80;
    border-radius: 3px;

}

.page_wrapper_edit {
  margin-bottom: 5px;
  display: flex;
  align-items: baseline;
  justify-content: center;
}