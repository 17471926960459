.react-flow__node {
  width: 47% !important;
  display: flex;
  align-items: center;
  font-size: 15px !important;
  padding-left: 20px !important;
  border-radius: 4px !important;
  border: 1px solid #224e8e !important;
  background: #fff;
}

.container {
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  width: 100%;

  .react-flow {
    height: 70vh !important;
    overflow: hidden !important;
    width: 100%;
  }
  .toogle-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .toogleLabel {
    color: #3979d8;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.text-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: #ffff;
  width: 100%;
  color: #1e1c80;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 10px 0 10px 0;
  align-items: center;
}

.icon-header {
  align-items: center;
  display: flex;
  gap: 1rem;
  img {
    width: 20px;
    height: 20px;
  }
}

.react-flow__panel {
  display: none;
}

.react-flow__handle {
  border: 3px solid #3979d8 !important;
  width: 10px !important;
  height: 10px !important;
  border-radius: 50px !important;
  background-color: #fff !important;
}

.react-flow__handle-right {
  right: -8px !important;
}

.react-flow__handle-left {
  left: -8px !important;
}

.container .react-flow {
  //   margin-bottom: 10rem;
}

.like-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 2.7rem;
  right: -3rem;
  transform: translateY(-50%);
}

.like-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-bottom: 10px;
}
.thumbsNotVisible {
  opacity: 0.4;
}

.pagination {
  display: flex;
  font-size: 18px;
  justify-content: space-around;
  img {
    width: 14px;
    cursor: pointer;
  }
  .pagination-content {
    .pagination-page-count {
      font-size: 14px;
      line-height: 0.5;
    }
    .pagination-page {
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 0.1;
    }
    // p {
    //   font-size: 18px;
    //   line-height: 0.1;
    // }
  }
}

.extra-div {
  text-align: center;
  margin-top: 1rem;
  color: white;
  border: 1px solid black;
  width: 45%;
  margin-right: 20px;
  padding: 10px;
  float: right;
}

.line {
  border-top: 4px solid #224e8e;
  width: 100%;
}

.chap-para-reactflow-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #1e1c80;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 3px;
  margin-bottom: 1rem;
  gap: 2px;
  background: white;
  z-index: 999 !important;
  position: absolute;
  left: 0;
  right: 0;
}

.confirm-post-comp {
  // position: absolute;
  // bottom: 0;
  position: fixed;
  bottom: 4rem;
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 16px;
  background: #3b37ff;
  cursor: pointer;
  border: none;
  padding: 1rem;
  left: 0;
  right: 0;
  width: 180px;
  left: 45%;
  z-index: 99;
}
