

#translated-editor-word-count{
  right: 0;
}

#sourced-editor-word-count{
    // border-right:1px solid rgb(211, 211, 211);
    left: 0;
}

.ck-word-count {
    display: flex;
    column-gap: 30px;
    font-size: 14px;
    padding: 7px;
    // margin: 0 auto 0 auto;
}

@media screen and (max-width: 1500px) {  
  .ck-word-count {
    font-size: 12px;
    // flex-direction: column;
  }
  .shortcuts{
    font-size: 12px;
  }
}


@media screen and (max-width: 1350px) {  
  .ck-word-count {
    font-size: 10px;
    // flex-direction: column;
  }
  .shortcuts{
    font-size: 10px;
  }
}

@media screen and (max-width: 1200px) {  
  .ck-word-count {
    // font-size: 10px;
    flex-direction: column;
    font-size: 12px;

  }
  .shortcuts{
    font-size: 16px;
  }
}

@media screen and (max-width: 450px) {  
  .ck-word-count {
    font-size: 11px;
  }
}