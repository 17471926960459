.confidence_info_wrapper {
  border-radius: 16px;
  border: 1px solid #1e1c80;
  box-shadow: 3px 11px 6px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 12px 0 21px;
  margin-left: auto;
  margin-right: 1rem;
}
.confidence_info_crossIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  overflow: hidden;
  margin-right: 12px;
  max-width: 100%;
  cursor: pointer;
}
@media (max-width: 991px) {
  .confidence_info_crossIcon {
    margin-right: 10px;
  }
}
.confidence_info_description_wrapper {
  align-self: stretch;
  display: flex;
  margin-top: 28px;
  width: 100%;
  flex-direction: column;
  padding: 0 12px 0 20px;
}
@media (max-width: 991px) {
  .confidence_info_description_wrapper {
    max-width: 100%;
  }
}
.slightly_low_confidence_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
@media (max-width: 991px) {
  .slightly_low_confidence_wrapper {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.slightly_low_child_1 {
  border: 1px solid #000;
  background-color: #fff3d1;
  display: flex;
  width: 12px;
  height: 33px;
  flex-direction: column;
}
.slightly_low_child_2 {
  justify-content: center;
  color: #000;
  align-self: start;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 14px Poppins, sans-serif;
}
@media (max-width: 991px) {
  .slightly_low_child_2 {
    max-width: 100%;
  }
}
.very_low_confidence_wrapper {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  gap: 16px;
}
@media (max-width: 991px) {
  .very_low_confidence_wrapper {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.very_low_child_1 {
  border: 1px solid #000;
  background-color: #ffc0c0;
  display: flex;
  width: 12px;
  height: 33px;
  flex-direction: column;
}
.very_low_child_2 {
  justify-content: center;
  color: #000;
  align-self: start;
  flex-grow: 1;
  flex-basis: auto;
  font: 400 14px Poppins, sans-serif;
}
@media (max-width: 991px) {
  .very_low_child_2 {
    max-width: 100%;
  }
}
.low_accuracy {
  display: flex;
  margin-top: 37px;
  justify-content: space-between;
  gap: 16px;
}
@media (max-width: 991px) {
  .low_accuracy {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.low_accuracy_child_1 {
  border: 1px solid #000;
  background-color: #fb4e4e;
  display: flex;
  width: 12px;
  height: 33px;
  flex-direction: column;
}
.low_accuracy_child_2 {
  justify-content: center;
  color: #000;
  align-self: center;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 400 14px Poppins, sans-serif;
}
@media (max-width: 991px) {
  .low_accuracy_child_2 {
    max-width: 100%;
  }
}
.high_accuracy {
  display: flex;
  margin-top: 38px;
  justify-content: space-between;
  gap: 16px;
}
@media (max-width: 991px) {
  .high_accuracy {
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.high_accuracy_child_1 {
  border: 1px solid #000;
  background-color: #3eb05e;
  display: flex;
  width: 12px;
  height: 33px;
  flex-direction: column;
}
.high_accuracy_child_2 {
  justify-content: center;
  color: #000;
  align-self: center;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font: 400 14px Poppins, sans-serif;
}
@media (max-width: 991px) {
  .high_accuracy_child_2 {
    max-width: 100%;
  }
}
