.alertDialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    // align-items: center;
    padding-top: 5rem;
    z-index: 99999;
    overflow: hidden;
  }
  
  .alertDialog-box {
    background-color: #fff;
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    // align-items: center;
    height: fit-content;
    width: 32rem;
    // justify-content: center;
    z-index: 99999;
    justify-content: space-between;
  }
  
  .alertDialog-message {
    margin-top: 0px;
    font-size: 16px;
  }
  
  .alertDialog-close {
    background-color: #E53E3E;
    color: #fff;
    border: none;
    padding: 8px 16px;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
  }
  
  
  .alertDialog-save {
    background-color: #E2E8F0;
    color: #1A202C;
    border: none;
    padding: 12px 16px;
    font-weight: 600;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
  }
  