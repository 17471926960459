@import "../../variables.scss";

.file-upload-input {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative
}

.upload-size {
  position: absolute;
  top: 31px;
  right: 0px;
  font-size: 10px;
  // font-weight: bold;
  color: grey;

}

.hidden-input {
  display: none;
}

.input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  //   border: 1px solid #ccc;
  border: 1px solid #1e1c80;
  border-radius: 10px;
  //   padding: 12px;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
  background-color: white;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40px;
  //   margin-right: 8px;
  //   margin-right: -14px;
  margin-right: -10px;
}

.folder-icon,
.arrow-icon {
  font-size: 18px;
}

.file-input {
  flex: 1;
  border: none;
  outline: none;
  background: none;
  color: $primary-color;
  font-family: Poppins;
  //   font-size: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.file-input::placeholder {
  //   color: $primary-color;
  color: #c8cbce;
  font-family: Poppins;
  //   font-size: 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
}

.arrow-icon {
  font-size: 18px;
}
.clear-icon {
  font-size: 20px;
  padding: 3px
}
