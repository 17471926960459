@import "../../variables.scss";

.container-pair-page {
  .header {
    display: flex;
    justify-content: space-between;
    color: #1e1c80;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    align-items: center;
    width: 100vw;
    padding-right: 2rem;

    background-color: white;
  }
  .content-header {
    color: #2d3250;
    text-align: left;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 0.7;
  }
  .header-title {
    // color: $primary-color;
    color: #1e1c80;
    font-family: Poppins;
    display: none;
    // font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    // width: 100vw;
    margin-left: 24px;

    @media (max-width: 768px) {
      font-size: 32px;
    }

    @media (max-width: 480px) {
      font-size: 24px;
    }
  }

  .header-title-edit {
    color: $primary-color;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    padding-left: 2rem;
    margin: 0;
    // width: 100vw;
    margin-top: 26px;
  }

  .back-arrow {
    margin-right: 2rem;
    cursor: pointer;
  }

  .content {
    display: flex;
    align-items: center;
    padding: 0 2rem 0 2rem;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
    }
  }

  .book-detail {
    flex: 1;
    color: #3979d8;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 0.7;

    @media (max-width: 768px) {
      font-size: 20px;
    }
  }

  .preview-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border: none;
    border-radius: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background: #87b7ff;
    color: #dae8ff;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media (max-width: 768px) {
      font-size: 20px;
      margin-top: 1rem;
    }
  }

  .eye-icon {
    font-size: 24px;
  }

  .line-container {
    padding: 0 2rem 0 2rem;

    .line {
      border-top: 4px solid #224e8e;
      margin-top: -0.7rem;
      width: 98%;
      margin-left: 16px;
    }

    .title {
      //   color: #224e8e;
      //   font-family: Poppins;
      //   font-size: 24px;
      //   font-style: normal;
      //   font-weight: 400;
      //   line-height: 0.5;
      color: #1e1c80;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .container-pairs {
    background: #98b3de;
    padding-bottom: 1rem;

    .container-pairs-header {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #ffff;
      height: 60px;
      //   opacity: 0.5;
      margin-bottom: 1rem;

      p {
        color: #3979d8;
        text-align: center;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media (max-width: 768px) {
          font-size: 24px;
        }
      }
    }

    .pair-row {
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #f0f0f0;
      height: 60px;

      p {
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }
    }
  }
}

.error-message {
  text-align: center;
  margin-top: 25%;
  font-size: 24px;
  color: red;
}

.user-menu {
  // border-radius: 16px;
  border-radius: 10px;
  background: #1e1c80;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.translated-text-header-zoombox-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.zoom-box {
  border-radius: 16px;
  border: solid 0.5px #3b37ff;
  cursor: pointer;
  width: 138px;

  height: 48px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .box1 {
    display: flex;
    justify-content: center;
    width: 50%;
    height: 100%;
    align-items: center;
    border-right: 1px #3b37ff solid;
    font-size: 16px;
    color: #3b37ff;
  }

  .box2 {
    display: flex;
    justify-content: center;
    width: 50%;
    height: 100%;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #3b37ff;
  }
}

.content-main-container {
  display: flex;
  align-items: center;
  padding-right: 1rem;
}

.hr-line {
  border-right: 2px solid blue;
  margin-right: 10px;
  margin-left: 10px;
  width: 2px;
  height: 48px;
}


.no-data {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.view-btn {
  color: #3b37ff;
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: white;
  border: none;
  border-radius: 4px;
  // padding: 6px 12px;
  // border-radius: 18px;
  cursor: pointer;
  padding: 10px 16px;
  margin-top: 10px;
  border:1px solid #3b37ff;
}

.error-data {
  color: crimson;
}

.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}