.side-tool-bar {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    // top: 3.8rem;
    top: 6rem;
    right: 3rem;
    margin-top: 3rem;   
  }
  .side-icon-book {
    width: 28px;
    height: 28px;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .side-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    margin-bottom: 10px;
  }