@import "./variables.scss";

.container {
  padding: 0 2rem 2rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  max-width: unset !important;
}

.App {
  min-height: 100vh;
  // background-color: #dae8ff;
  // background-image: url("./assets/backdrop.png");
  background-size: cover;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.spacer {
  pointer-events: none;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc1JyBoZWlnaHQ9JzUnPgogIDxyZWN0IHdpZHRoPSc1JyBoZWlnaHQ9JzUnIGZpbGw9J3doaXRlJy8+CiAgPHBhdGggZD0nTTAgNUw1IDBaTTYgNEw0IDZaTS0xIDFMMSAtMVonIHN0cm9rZT0nIzg4OCcgc3Ryb2tlLXdpZHRoPScxJy8+Cjwvc3ZnPg=="); background-repeat: repeat;
  opacity: 0.5;
  display: none; 
  margin: 0 !important;
}


.no-scroll {
  overflow: hidden;
}