.node-sidebar {
  position: fixed;
  //   top: 0;
  //   right: 0;
  //   width: 300px;
  top: 11rem;
  right: 26.9rem;
  height: 100%;
  background: #ebfefe;
  box-shadow: -1px 0px 5px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  transform: translateX(100%);
  z-index: 999;
}

.node-sidebar.open {
  transform: translateX(0);
}

.node-sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.node-sidebar-header h2 {
  margin: 0;
  font-size: 20px;
}

.close-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #333;
  padding: 0;
}

.node-sidebar-content {
}

@media (max-width: 768px) {
  .node-sidebar {
    width: 100%;
  }
}

.btn-container-node {
  text-align: center;
  margin-top: 5rem;
  .close-button,
  .submit-node-button {
    font-size: 18px;
    padding: 8px 16px 8px 16px;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 8px;
    border: none;
    cursor: pointer;
  }
  .submit-node-button {
    background-color: #3b37ff;
  }

  .close-button {
    color: #ee2424;
  }
}

.connected-nodes-container {
  // margin-bottom: 3rem;
}

.header-text-nodesidebar {
  color: #1e1c80;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.header-top-text-nodesidebar {
  color: #1e1c80;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.separator-nodesidebar {
  border-bottom: 1px solid #1e1c80;
  margin-bottom: 20px;
}

.label-textarea {
  border-radius: 4px;
  border: 1px solid #1e1c80;
  background: #fff;
}

.chap-para-nodesidebar {
  color: #1e1c80;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.view-english-toogle-container {
  display: flex;
  justify-content: space-between;
  .toogle-container {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    .switch {
      position: relative;
      display: inline-block;
      width: 30px;
      height: 16px;
      margin-right: 5px;
    }
  }
}
