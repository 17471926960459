.dashboard {
  padding: 20px;
  width: 97%;
}
.dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .dashboard-title {
    color: #1e1c80;
    font-family: Poppins;
    font-size: 24px;
    line-height: normal;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    // border-radius: 16px;
    border-radius: 10px;
    // background: #1e1c80;
    display: flex;
    // width: 410px;
    // height: 48px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    text-align: center;
  }
}
.dashboard-sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.txt-btn-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .add-books-btn {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    // border-radius: 16px;
    border-radius: 10px;
    background: #3b37ff;
    cursor: pointer;
    border: none;
    width: 303px;
    height: 48px;
  }
  .search-input-dashboard {
    border-radius: 16px;
    border: 1px solid #3B37FF;
    color: #c8cbce;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

h1 {
  font-size: 24px;
  font-weight: bold;
}

.card-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.optmor {
  cursor: pointer;
  color: white;
}

@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
  }
}

.search-input-dashboard {
  border-radius: 10px;
  border: 1px solid #1E1C80;
  color: #1E1C80;
  padding:  12px 22px ;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  width: 200px;
  
}